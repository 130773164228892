.admissions {
  padding: 20px;
}

.admission-process,
.requirements,
.deadlines {
  margin-bottom: 40px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
}
