/* Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  border-radius: 20px;
}

.popup-content {
  padding: 20px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  width: 70%; /* Set width to 80% of the overlay */
  max-width: 600px; /* Optional: Limit the maximum width of the popup */
}

.popup-content img {
  width: 100%; /* Make image responsive within the popup */
  height: auto; /* Maintain aspect ratio */
  max-height: 500px; /* Limit the maximum height of the image */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-style: bold;
}
/* Smooth scrolling */
html {
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
}

/* General container for the section */
.update-section {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to new lines */
  justify-content: space-between;
  padding: 20px;
  overflow: hidden; /* Hide scrollbars */
}

/* Program card styling */
.update-program-card {
  flex: 0 0 40%;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.update-program-card:hover {
  transform: translateY(-10px); /* Hover effect */
}

/* Popup content styling */
.update-popup-content {
  flex: 0 0 60%;
  background-color: #fff;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
  overflow: hidden;
}

/* Image styling */
.update-popup-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  object-fit: contain;
}

/* Hide scrollbar for all elements */
.update-section::-webkit-scrollbar,
.update-program-card::-webkit-scrollbar,
.update-popup-content::-webkit-scrollbar {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .update-section {
    flex-direction: column; /* Stack items vertically */
  }

  .update-program-card,
  .update-popup-content {
    flex: 0 0 100%; /* Full width */
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .update-section {
    flex-direction: column; /* Ensure vertical stacking */
  }

  .update-program-card {
    order: 1; /* Ensure text content is first */
  }

  .update-popup-content {
    order: 2; /* Ensure image is second */
  }
}
