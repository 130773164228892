/* Home.css */

.home {
  padding: 20px;
}

.hero {
  text-align: center;
  background: url("../assets/rising-star-bg.jpg") no-repeat center center;
  background-size: cover;
  padding: 100px 20px;
  margin-bottom: 20px;
  color: white;
  border-radius: 20px;
  position: relative;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2em;
}

.testimonials,
.call-to-action {
  margin-bottom: 40px;
}

/* Gallery Section */
.gallery {
  text-align: center;
  position: relative;
}

.gallery h2 {
  font-size: 2rem;
  color: #2b6cb0;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.75rem;
  display: inline-block; /* Makes the border only extend to the text width */
  border-left: 4px solid #2b6cb0;
  padding-left: 0.5rem;
}

.bg-gray {
  border-radius: 20px;
  background-color: gray;
}

.gallery img {
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.gallery-div {
  width: 100%;
  height: 300px;
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .thumb {
  display: none; /* Hide thumbnails */
}

.program-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  opacity: 0.5; /* Start with opacity 0 */
  transform: scale(0.8); /* Start with a smaller scale */
  animation-fill-mode: forwards;
}

.program-card:hover {
  transform: scale(1.05);
}

.featured-programs {
  margin-bottom: 40px;
  text-align: center;
}

.featured-programs.visible {
  animation: fadeInScale 0.5s forwards;
}

.featured-programs.hidden {
  animation: fadeOutScale 0.5s forwards;
}

/* Add classes for visibility change */
.program-card.visible {
  animation: fadeInScale 0.5s forwards;
}

.program-card.hidden {
  animation: fadeOutScale 0.5s forwards;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Start smaller */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Scale to normal size */
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1); /* Normal size */
  }
  100% {
    opacity: 0;
    transform: scale(0.8); /* Shrink and fade out */
  }
}

.featured-programs h2 {
  font-size: 2rem;
  color: #2b6cb0;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.75rem;
  display: inline-block; /* Makes the border only extend to the text width */
  border-left: 4px solid #2b6cb0;
  padding-left: 0.5rem;
}

.program-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}

.program-icon {
  color: #007bff;
  margin-bottom: 10px;
}

.testimonial-item {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel {
  width: 100%;
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 10;
}

.carousel-text h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.carousel-text p {
  font-size: 1.2em;
}

.announcements {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.announcements h2 {
  font-size: 2rem;
  color: #2b6cb0;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.75rem;
  display: inline-block; /* Makes the border only extend to the text width */
  border-left: 4px solid #2b6cb0;
  padding-left: 0.5rem;
}

.announcements ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.announcements li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Start with opacity 0 */
  transform: scale(0.8); /* Start with a smaller scale */
  animation-fill-mode: forwards; /* Maintain the animation state after completion */
}

.announcements li.visible {
  animation: fadeInScale 0.5s forwards;
}

.announcements li.hidden {
  animation: fadeOutScale 0.5s forwards;
}

.call-to-action {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* Adjust the max width if needed */
  margin: 20px auto;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.cta-button {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 1.1em;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.cta-button:hover {
  background-color: #0056b3;
}

.cta-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.event-icon {
  font-size: 2.5em;
  margin-right: 15px;
  color: #007bff; /* Adjust icon color as needed */
}

.event-details {
  flex: 1;
}

.event-details h3 {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #333;
}

.event-details p {
  margin: 5px 0;
  font-size: 1.1em;
  color: #555;
}

.icon-small {
  font-size: 1.2em;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .hero {
    padding: 50px 20px;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1em;
  }

  .gallery {
    margin-bottom: 20px;
  }

  .gallery-div {
    height: 200px; /* Smaller height for mobile */
  }

  .gallery img {
    width: 100%; /* Ensure images are responsive */
    height: auto; /* Maintain aspect ratio */
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0.5;
    transform: scale(18);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
}
