/* Container for the contact form */
.contact-us {
  max-width: 700px;
  margin: 10px auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px -4px 6px rgba(0, 0, 0, 0.1),
    4px 0px 6px rgba(0, 0, 0, 0.1), -4px 0px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
h1 {
  color: #333;
  font-size: 2.5rem;
  font-family: "Arial", sans-serif;
}

/* Contact form styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact-form label {
  font-size: 1.1rem;
  font-weight: 600;
  color: #555;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  padding: 11px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #12325b;
  background-color: #fff;
}

.contact-form button {
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #12325b;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #12325b;
}

/* Styling for the contact icons section */
.contact-icons {
  display: flex;
  flex-direction: row;
  gap: 34px;
  margin-top: 21px;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Aligns items vertically at the center */
}

.contact-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.contact-item .material-icons {
  font-size: 2rem;
  color: #12325b;
  transition: color 0.3s ease;
}

.contact-item:hover .material-icons {
  color: #12325b;
}

.contact-link {
  text-decoration: none;
  color: #12325b;
  font-size: 1.1rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #12325b;
  text-decoration: underline;
}

/* Make the page mobile-responsive */
@media (max-width: 768px) {
  .contact-us {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .contact-form label {
    font-size: 1rem;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9rem;
  }

  .contact-item .material-icons {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .contact-us {
    padding: 15px;
    max-width: 90%;
  }

  h1 {
    font-size: 1.8rem;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.9rem;
    padding: 10px;
  }

  .contact-form button {
    font-size: 1rem;
  }

  .contact-item .material-icons {
    font-size: 2.2rem;
  }

  .contact-link {
    font-size: 1rem;
  }
}
