.updates-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
}

.updates-container h3 {
  margin: 0 0 10px 0;
  padding: 10px 0;
  text-align: left;
}

.updates-list-wrapper {
  width: 100%;
  height: 500px; /* Adjust this height based on how many items you want visible */
  overflow: scroll; /* Hide overflow to create a scrolling effect */
  position: relative;
  scrollbar-width: none;
}

.updates-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute; /* Position it for animation */
  animation: scroll-up 30s linear infinite; /* Adjust duration for speed */
}

.update-item {
  height: auto;
  width: 100%;
  text-align: left;
  padding-left: 30px; /* Space for bullet */
  cursor: pointer;
  margin: 5px 0;
  line-height: 1.5rem;
  position: relative;
}

.update-item::before {
  content: "•"; /* Bullet point */
  position: absolute;
  left: 10px; /* Position the bullet point */
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem; /* Adjust size of bullet */
  color: #000; /* Bullet color */
}

.update-item a {
  text-decoration: underline;
  color: blue;
}

@keyframes scroll-up {
  0% {
    transform: translateY(10%); /* Start from the bottom */
  }
  100% {
    transform: translateY(-100%); /* End at the top */
  }
}
