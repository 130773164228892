/* AboutUs.css */

.about-us {
  padding-right: 5%;
  padding-left: 5%;
  max-width: 1200px;
  margin: auto;
  /* background: linear-gradient(to right, #f8f9fa, #ffffff);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  transition: transform 0.3s;
}
.about-us-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #2c3e50; /* A deep, professional color */
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  font-family: "Roboto", sans-serif; /* Use a modern, clean font */
}

/* Add a subtle underline effect */
.about-us-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background-color: #e67e22; /* Accent color */
  margin: 10px auto;
  border-radius: 2px;
}

/* Optional shadow for a bit of depth */
.about-us-title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.about-us:hover {
  transform: translateY(-10px);
}

.about-us h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #2d3748;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.school-history,
.mission-vision,
.faculty {
  background: #f0f4f8;
  padding: 1%;
  margin: 1.5% 2.5%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.school-history:hover,
.mission-vision:hover,
.faculty:hover {
  transform: translateY(-5px);
}

h2 {
  font-size: 2rem;
  color: #2b6cb0;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.75rem;
  border-left: 4px solid #2b6cb0;
  padding-left: 0.5rem;
}

p {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #4a5568;
  margin-bottom: 0;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

.about-us p {
  margin-top: 1.25rem;
  padding: 0.3rem;
}

@media (max-width: 768px) {
  .about-us {
    padding: 4%;
  }

  .about-us h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1rem;
  }
}
