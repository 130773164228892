/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #12325b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  font-style: bold;
}

.logo-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #fff;
}

.menu-icon {
  cursor: pointer;
  display: none;
}

.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin: 0 10px;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
}

.navbar-menu li:hover {
  transform: scale(1.1);
}

.navbar-menu a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.navbar-menu a:hover {
  color: red;
  cursor: pointer;
}

.navbar-menu a.active-link {
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
a {
  text-decoration: none;
}
@media (max-width: 768px) {
  .navbar {
    height: 50px;
  }
  .menu-icon {
    display: block;
    color: white;
  }
  .navbar-logo {
    font-size: 18px;
  }

  .navbar-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #12325b;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    height: auto;
    z-index: 100;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
  }
}
