/* Academics.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.academics {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4; /* Light background for the entire page */
  color: #333;
  padding: 2rem;
}

h1.title {
  text-align: center;
  color: #35424a; /* Darker color for the main title */
  margin-bottom: 1.5rem;
}

section {
  margin: 18px auto;
  /* background: #ffffff; White background for sections */
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); Subtle shadow for depth */
}

.academics h3 {
  color: #2980b9; /* Blue for section headings */
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db; /* Underline for headers */
  padding-bottom: 0.5rem;
  padding: 5px;
  margin: 10px;
  border-left: 2fpx solid #3498db;
}

p {
  margin: 0.5rem 0 1rem;
  text-indent: 10%;
}

ul {
  margin-left: 1.5rem; /* Indent for lists */
}

li {
  margin-bottom: 0.5rem;
}

strong {
  color: #e74c3c; /* Emphasis color for important text */
}

.image-container {
  text-align: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.department-list,
.activities {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.department-list article,
.activity {
  flex: 1;
  padding: 10px;
  margin: 0 10px;
  background: #e2e2e2; /* Light gray for activity cards */
  border-radius: 8px;
}

footer {
  text-align: center;
  padding: 10px;
  background: #35424a;
  color: #ffffff;
  position: relative;
  bottom: 0;
  width: 100%;
}

.sports-list {
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
  justify-content: space-around; /* Distribute space between items */
}
@keyframes appear {
  from {
    opacity: 0;
    clip-path: inset(100% 100% 0 0);
  }
  to {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
}
.sport-item,
.performing-arts-item {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center items horizontally */
  margin: 18px 0; /* Margin between items */
  border: 1px solid #e2e2e2; /* Optional border for items */
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
  background: #f9f9f9; /* Light background for items */
  width: 32%;
  height: 250px;
  opacity: 0; /* Start with transparent */
  transform: scale(0.8); /* Start with a smaller scale */

  animation: fadeInScale 0.5s forwards;
  animation-timeline: view();
  animation-range: entry 10% cover 50%;
}

/* Define the fade-in and scale-up animation */
@keyframes fadeInScale {
  to {
    opacity: 1; /* Fade in to full opacity */
    transform: scale(1); /* Scale up to normal size */
  }
}

.sport-image,
.performing-arts-image {
  /* Uniform class for image size */
  width: 120px; /* Fixed small width */
  height: 120px; /* Fixed small height for uniformity */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 5px; /* Space below the image */
  display: block; /* Ensure it's treated as a block element */
  margin-left: auto; /* Center image horizontally */
  margin-right: auto; /* Center image horizontally */
}
.sport-deccription-p {
  font-size: 11px;
  word-spacing: 1px;
}

.sport-description strong {
  display: block; /* Make the name a block for better layout */
  margin-bottom: 5px; /* Space between name and description */
}

.performing-arts-list {
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
  justify-content: space-around; /* Distribute space between items */
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .academics {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  h1.title {
    font-size: 1.8rem; /* Adjust main title size */
  }

  h3 {
    font-size: 1.5rem; /* Adjust section heading size */
  }

  h3 {
    font-size: 1.2rem; /* Adjust subsection heading size */
  }

  .department-list,
  .activities {
    flex-direction: column; /* Stack items vertically on smaller screens */
    margin: 0; /* Remove margins on small screens */
  }

  .department-list article,
  .activity {
    margin-bottom: 1rem; /* Add space between stacked items */
  }
}
