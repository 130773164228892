/* TermsOfService.css */

.terms-of-service {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.terms-of-service h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.terms-of-service h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.terms-of-service p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

.terms-of-service ul {
  list-style-type: disc;
  margin-left: 20px;
}

.terms-of-service li {
  margin-bottom: 10px;
}
